<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="attachmentsRules"
  >
    <b-form
      v-if="evaluationRequestData"
      class="mt-1"
      @submit.prevent="handleSubmit(addEvaluationRequest)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-alert
            v-model="showMessage"
            dismissible
            :variant="messageType"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                class="ml-25"
                v-html="message"
              />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div class="d-flex">
            <feather-icon
              icon="BookOpenIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              صور العقار
            </h4>
          </div>
        </b-col>
      </b-row>
      <!-- Loop for images -->
      <b-row
        v-for="(image, index) in evaluationRequestData.images"
        :id="`image-${image.id}`"
        :key="`image-${image.id}`"
        ref="imageRow"
      >
        <b-col
          cols="12"
          md="5"
          lg="5"
        >
          <!-- Field: path -->
          <validation-provider
            #default="validationContext"
            :vid="`image_path_${index}`"
            :name="`image_path_${index}`"
            rules="size:4096"
          >
            <b-form-group
              label="الصور"
              :label-for="`path_${index}`"
              :state="getValidationState(validationContext)"
            >
              <b-form-file
                :id="`image_path_${index}`"
                v-model="image.path"
                accept="image/*"
                browse-text="اختر الصور"
                placeholder="اختر ملفا ..."
                multiple
              />
              <b-button 
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="btn-sm mt-2"
                variant="primary"
                @click="image.path = null">إعادة ضبط</b-button>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div class="d-flex">
            <feather-icon
              icon="BookOpenIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              صور الوثائق المرفقة
            </h4>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-for="(item, index) in evaluationRequestData.files"
        :id="item.id"
        :key="item.id"
        ref="row"
      >
        <b-col
          cols="12"
          md="5"
          lg="5"
        >
          <!-- Field: path -->
          <validation-provider
            #default="validationContext"
            :vid="`path_${index}`"
            :name="`path_${index}`"
          >
            <b-form-group
              label="المرفق"
              :label-for="`path_${index}`"
              :state="getValidationState(validationContext)"
            >
              <b-form-file
                :id="`path_${index}`"
                v-model="item.path"
                accept="image/*, application/pdf"
                browse-text="اختر المرفق"
                placeholder="اختر ملفا ..."
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="5"
          lg="5"
        >
          <!-- Field: type -->
          <validation-provider
            #default="validationContext"
            :vid="`type_${index}`"
            :name="`type_${index}`"
          >
            <b-form-group
              label=" نوع المرفق"
              :label-for="`type_${index}`"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="item.type"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="attachmentsTypes"
                :reduce="(val) => val.value"
                :clearable="false"
                :input-id="`type_${index}`"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="item.type =='أخرى' "
          cols="12"
          md="5"
          lg="5"
        >
          <!-- Field: type -->
          <!-- rules="required" -->
          <validation-provider
            #default="validationContext"
            :vid="`label_${index}`"
            :name="`label_${index}`"
          >
            <b-form-group
              label=" وصف المرفق"
              :label-for="`label_${index}`"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="`label_${index}`"
                v-model="item.label"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="5"
          lg="5"
        >
          <!-- Field: type -->
          <validation-provider
            #default="validationContext"
            :vid="`show_on_report_${index}`"
            :name="`show_on_report_${index}`"
          >
            <b-form-group
              label="اظهار في التقريير"
              :label-for="`show_on_report_${index}`"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox
                v-model="item.show_on_report"
                class="mr-0 mt-50"
                name="`show_on_report_${index}`"
                switch
                inline
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Remove Button -->
        <b-col
          v-if="evaluationRequestData.files.length > 1"
          cols="12"
          md="2"
          lg="2"
          class="mb-50"
        >
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>حدف</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-sm"
            variant="primary"
            @click="addItem"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>إضافة مرفق</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <br>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div class="d-flex">
            <feather-icon
              icon="BookOpenIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              الملاحظات
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <b-form-textarea
            v-model="evaluationRequestData.notes"
            placeholder="اكتب ملاحظاتك هنا ..."
            rows="3"
            max-rows="6"
          />
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BFormFile,
  BButton,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { heightTransition } from '@core/mixins/ui/transition';
import { serialize } from 'object-to-formdata';
import evaluationRequestStore from '../../evaluationRequestStore';

export default {
  components: {
    BFormFile,
    BButton,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin, heightTransition],
  data() {
    return {
      isLoading: false,
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  mounted() {
    this.$root.$on('validateAttachments', async () => {
      this.$refs.attachmentsRules.validate().then((success) => {
        this.evaluationRequestData.attachments = [
          ...this.evaluationRequestData.files || [],
          ...(this.evaluationRequestData.images || []).map((image) => {
            const paths = image.path || []; 
            return paths.map((path) => ({
              path,
              type: 'صور العقار', 
              show_on_report: 1,
            }));
          }).flat(),
        ];

        this.evaluationRequestData.attachments = this.evaluationRequestData.attachments.map((attachment) => {
          if (attachment.hasOwnProperty('show_on_report') && attachment.show_on_report) {
            attachment.show_on_report = 1; // Set to 1 for true
          } else {
            attachment.show_on_report = 0; // Set to 0 for false
          }

          return attachment;
        });

        this.$root.$emit('attachmentsValidated', {
          success,
          data: this.evaluationRequestData,
        });
      });
    });
  },
  beforeDestroy() {
    this.$root.$off('validateAttachments');
  },
  methods: {
    addItem() {
      this.evaluationRequestData.files.push({});

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.evaluationRequestData.files.splice(index, 1);
    },

  },
  setup(props) {
    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluationRequest';

    // Register evaluationRequest
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationRequestStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
    });
    const blankEvaluationRequestData = {
      images: [{}],
      files: [{}],
    };

    const attachmentsTypes = [
      {
        label: 'صورة للصك',
        value: 'صورة للصك',
      },
      {
        label: 'صورة لطلب التثمين',
        value: 'صورة لطلب التثمين',
      },
      {
        label: 'أخرى',
        value: 'أخرى',
      },
    ];

    const evaluationRequestData = ref(
      JSON.parse(JSON.stringify(blankEvaluationRequestData)),
    );
    const resetevaluationRequestData = () => {
      evaluationRequestData.value = JSON.parse(
        JSON.stringify(blankEvaluationRequestData),
      );
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetevaluationRequestData,
    );

    return {
      attachmentsTypes,
      evaluationRequestData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

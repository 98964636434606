<template>
  <div v-if="contractData">
    <evaluation-request-add
      :contract="contractData"
      :cost="costData"
    />
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import contractStore from '@modules/contract/contractStore';
import EvaluationRequestAdd from './EvaluationRequestAdd.vue';

export default {
  components: {
    EvaluationRequestAdd,
  },
  setup() {
    const contractData = ref(null);
    const costData = ref(null);
    const CONTRACT_APP_STORE_MODULE_NAME = 'app-contract';

    // Register module
    if (!store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.registerModule(CONTRACT_APP_STORE_MODULE_NAME, contractStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_APP_STORE_MODULE_NAME);
    });

    store.dispatch('app-contract/fetchCost', { id: router.currentRoute.params.id })
      .then((response) => {
        const { data: cost, contract } = response.data;
        contractData.value = contract;
        costData.value = cost;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          contractData.value = undefined;
        }
      });

    return {
      contractData,
      costData,
    };
  },
};
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

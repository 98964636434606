<template>
   <div  v-if="isLoadingStart">
    <div class="d-flex justify-content-center mb-1">
      <b-spinner label="Loading..." />
    </div>
  </div>
  <validation-observer
    v-else     #default="{ handleSubmit }"
    ref="dimensionInfoRules"
  >
    <b-form
      v-if="evaluationRequestData"
      class="mt-1"
      @submit.prevent="handleSubmit(addEvaluationRequest)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div class="d-flex">
            <feather-icon
              icon="CompassIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              الحدود والأطوال
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-alert
            v-model="showMessage"
            dismissible
            :variant="messageType"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                class="ml-25"
                v-html="message"
              />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <!-- north -->
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: north -->
          <validation-provider
            #default="validationContext"
            vid="north"
            name="north"
          >
            <b-form-group
              label="شمالاً"
              label-for="north"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="north"
                v-model="evaluationRequestData.dimension.north"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: north_length -->
          <label
            for="north_length"
            style="margin-left: 12px"
          >بطول</label>
          <!-- Add item to north_length -->
          <b-button
            class="btn-icon rounded-circle btn-sm"
            variant="primary"
            style="width: 14px; height: 14px; padding: 0"
            size="sm"
            @click="addItem('north_length')"
          >
            <feather-icon
              icon="PlusIcon"
              size="10"
            />
          </b-button>
          <b-row>
            <b-col
              v-for="(length, index) in evaluationRequestData.dimension
                .north_length"
              :key="index"
            >
              <validation-provider
                #default="validationContext"
                :vid="`north_length${index}`"
                :name="`north_length${index}`"
              >
                <b-form-group
                  label-for="`north_length${index}`"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="`north_length${index}`"
                    v-model="
                      evaluationRequestData.dimension.north_length[index]
                    "
                  />
                  <!-- remove north_length item -->
                  <b-button
                    v-if="index > 0"
                    class="btn-icon rounded-circle"
                    variant="danger"
                    size="sm"
                    style="
                      position: absolute;
                      top: 12px;
                      left: 20px;
                      width: 14px;
                      height: 14px;
                      padding: 0;
                    "
                    @click="removeItem('north_length', index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="12"
                    />
                  </b-button>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <!-- end north -->
        <!-- south -->
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: south -->
          <validation-provider
            #default="validationContext"
            vid="south"
            name="south"
          >
            <b-form-group
              label="جنوباً"
              label-for="south"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="south"
                v-model="evaluationRequestData.dimension.south"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: south_length -->
          <label
            for="south_length"
            style="margin-left: 12px"
          >بطول</label>
          <!-- Add item to south_length -->
          <b-button
            class="btn-icon rounded-circle btn-sm"
            variant="primary"
            style="width: 14px; height: 14px; padding: 0"
            size="sm"
            @click="addItem('south_length')"
          >
            <feather-icon
              icon="PlusIcon"
              size="10"
            />
          </b-button>
          <b-row>
            <b-col
              v-for="(length, index) in evaluationRequestData.dimension
                .south_length"
              :key="index"
            >
              <validation-provider
                #default="validationContext"
                :vid="`south_length${index}`"
                :name="`south_length${index}`"
              >
                <b-form-group
                  label-for="`south_length${index}`"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="`south_length${index}`"
                    v-model="
                      evaluationRequestData.dimension.south_length[index]
                    "
                  />
                  <!-- remove south_length item -->
                  <b-button
                    v-if="index > 0"
                    class="btn-icon rounded-circle"
                    variant="danger"
                    size="sm"
                    style="
                      position: absolute;
                      top: 12px;
                      left: 20px;
                      width: 14px;
                      height: 14px;
                      padding: 0;
                    "
                    @click="removeItem('south_length', index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="12"
                    />
                  </b-button>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <!-- end south -->
        <!-- east -->
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: east -->
          <validation-provider
            #default="validationContext"
            vid="east"
            name="east"
          >
            <b-form-group
              label="شرقاً"
              label-for="east"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="east"
                v-model="evaluationRequestData.dimension.east"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: east_length -->
          <label
            for="east_length"
            style="margin-left: 12px"
          >بطول</label>
          <!-- Add item to east_length -->
          <b-button
            class="btn-icon rounded-circle btn-sm"
            variant="primary"
            style="width: 14px; height: 14px; padding: 0"
            size="sm"
            @click="addItem('east_length')"
          >
            <feather-icon
              icon="PlusIcon"
              size="10"
            />
          </b-button>
          <b-row>
            <b-col
              v-for="(length, index) in evaluationRequestData.dimension
                .east_length"
              :key="index"
            >
              <validation-provider
                #default="validationContext"
                :vid="`east_length${index}`"
                :name="`east_length${index}`"
              >
                <b-form-group
                  label-for="`east_length${index}`"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="`east_length${index}`"
                    v-model="evaluationRequestData.dimension.east_length[index]"
                  />
                  <!-- remove east_length item -->
                  <b-button
                    v-if="index > 0"
                    class="btn-icon rounded-circle"
                    variant="danger"
                    size="sm"
                    style="
                      position: absolute;
                      top: 12px;
                      left: 20px;
                      width: 14px;
                      height: 14px;
                      padding: 0;
                    "
                    @click="removeItem('east_length', index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="12"
                    />
                  </b-button>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <!-- end east -->
        <!-- west -->
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: west -->
          <validation-provider
            #default="validationContext"
            vid="west"
            name="west"
          >
            <b-form-group
              label="غرباً"
              label-for="west"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="west"
                v-model="evaluationRequestData.dimension.west"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: west_length -->
          <label
            for="west_length"
            style="margin-left: 12px"
          >بطول</label>
          <!-- Add item to west_length -->
          <b-button
            class="btn-icon rounded-circle btn-sm"
            variant="primary"
            style="width: 14px; height: 14px; padding: 0"
            size="sm"
            @click="addItem('west_length')"
          >
            <feather-icon
              icon="PlusIcon"
              size="10"
            />
          </b-button>
          <b-row>
            <b-col
              v-for="(length, index) in evaluationRequestData.dimension
                .west_length"
              :key="index"
            >
              <validation-provider
                #default="validationContext"
                :vid="`west_length${index}`"
                :name="`west_length${index}`"
              >
                <b-form-group
                  label-for="`west_length${index}`"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="`west_length${index}`"
                    v-model="evaluationRequestData.dimension.west_length[index]"
                  />
                  <!-- remove west_length item -->
                  <b-button
                    v-if="index > 0"
                    class="btn-icon rounded-circle"
                    variant="danger"
                    size="sm"
                    style="position: absolute;
                      top: 12px;
                      left: 20px;
                      width: 14px;
                      height: 14px;
                      padding: 0;
                    "
                    @click="removeItem('west_length', index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="12"
                    />
                  </b-button>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <!-- end west -->
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div class="d-flex">
            <feather-icon
              icon="MapIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              المساحات
              <!-- Add item to areas -->
              <b-button
                class="btn-icon rounded-circle btn-sm"
                variant="primary"
                style="width: 14px; height: 14px; padding: 0"
                size="sm"
                @click="addArea()"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="10"
                />
              </b-button>
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="(item, index) in evaluationRequestData.areas"
          :id="item.id"
          :key="index"
          ref="row"
          cols="12"
          md="12"
          lg="12"
        >
          <b-row>
            <b-col>
              <!-- Field: property_area_id -->
              <validation-provider
                #default="validationContext"
                vid="property_area_id"
                name="property_area_id"
                rules="required"
              >
                <b-form-group
                  label="نوع المساحة"
                  label-for="property_area_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="item.property_area_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="allAreas"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="property_area_id"
                    @input="onAreaChange(item, index)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- Field: label -->
              <validation-provider
                #default="validationContext"
                vid="label"
                name="label"
              >
                <b-form-group
                  label="مسمى المساحة"
                  label-for="label"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="label"
                    v-model="item.label"
                    :value="item.name"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- Field: surface -->
              <validation-provider
                #default="validationContext"
                vid="surface"
                name="surface"
              >
                <b-form-group
                  label="المساحة"
                  label-for="surface"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="surface"
                    v-model="item.surface"
                    type="number"
                    step="0.01"
                    value="0"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-button
                  v-if="index > 0"
                  class="btn-icon"
                  variant="danger"
                  size="sm"
                  style="position: absolute;
                      top: 30px;
                      left: 20px;
                      width: 18px;
                      height: 18px;
                      padding: 0;
                    "
                  @click="removeArea(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    size="12"
                  />
                </b-button>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import {
  BButton,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted, onMounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import evaluationRequestStore from '../../evaluationRequestStore';

export default {
  components: {
    BButton,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    BSpinner,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin],
  props: {
    propertyType: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  mounted() {
    this.$root.$on('validateDimensions', async (data) => {
      this.$refs.dimensionInfoRules.validate().then((success) => {
        this.$root.$emit('dimensionsValidated', {
          success,
          data: this.evaluationRequestData,
        });
      });
    });
  },
  beforeDestroy() {
    this.$root.$off('validateDimensions');
  },
  methods: {
    onAreaChange(item, index) {
      this.evaluationRequestData.areas[index].label = this.areas.find(
        (area) => area.value === item.property_area_id,
      ).label;
    },
    addItem(key) {
      this.evaluationRequestData.dimension[key].push(0);
    },
    removeItem(key, index) {
      this.evaluationRequestData.dimension[key].splice(index, 1);
    },
    addArea() {
      this.evaluationRequestData.areas.push({

      });
    },
    removeArea(index) {
      this.evaluationRequestData.areas.splice(index, 1);
    },
    addEvaluationRequest() {
      this.$refs.refFormObserver
        .validate()
        .then(async (success) => {
          if (success) {
            this.$store
              .dispatch(
                'evaluationRequest/addEvaluationRequest',
                this.evaluationRequestData,
              )
              .then((response) => {
                this.$toast.success(response.data.message);
                router.push('/evaluation-request').catch(() => {});
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status === 422) {
                    this.$toast.error(err.response.data.message);
                    const { message, errors } = err.response.data;
                    if (errors) {
                      const errorMessage = {
                        message: '',
                        type: 'danger',
                      };
                      Object.values(errors).forEach((error) => {
                        errorMessage.message += `${error[0]}<br>`;
                      });
                      this.displayMessage(errorMessage);
                    }
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: message,
                        text: message,
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                      },
                    });
                  } else {
                    this.$toast.error(err.response.data.error);
                  }
                }
                console.log(err);
              });
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              this.$refs.refFormObserver.setErrors(err.response.data.errors);
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },
  setup(props) {
    const isLoadingStart = ref(true); 
    const { propertyType } = props;
    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluationRequest';
    const evaluationRequestData = ref(null);
    // Register evaluationRequest module
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationRequestStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
    });

    const areas = ref([]);
    const allAreas = ref([]);
    onMounted(async () => {
      try {
        // Fetch all areas
        const allAreasResponse = await store.dispatch('evaluationRequest/fetchAllAreas');
        const { data: allAreasData } = allAreasResponse.data;
        allAreas.value = allAreasData.map((area) => ({
          ...area,
          value: area.id,
          label: area.name,
        }));

    // Retrieve related areas
    if (propertyType) {
          const areasResponse = await store.dispatch('evaluationRequest/fetchAreas', { id: propertyType });
          const { data } = areasResponse.data;
          areas.value = data.map((area) => ({
            label: area.name,
            value: area.id,
          }));
          const [first] = data.map((a) => ({
            surface: 0,
            property_area_id: a.id,
            label: a.name,
            name: a.name,
          }));
          const blankEvaluationRequestData = {
            dimension: {
              north: '',
              north_length: [0],
              south: '',
              south_length: [0],
              west: '',
              west_length: [0],
              east: '',
              east_length: [0],
            },
            areas: [first],
          };

          evaluationRequestData.value = blankEvaluationRequestData;
        }
      } catch (error) {
        console.error('Error fetching all areas:', error);
        evaluationRequestData.value = undefined;
      } finally {
        isLoadingStart.value = false;
      }
    });

    const resetevaluationRequestData = () => {
      evaluationRequestData.value = JSON.parse(
        JSON.stringify(evaluationRequestData.value)
      );
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetevaluationRequestData
    );

    return {
      isLoadingStart,
      areas,
      allAreas,
      evaluationRequestData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
